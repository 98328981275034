import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InternalTrade } from '@app/_models/internal-trade';

@Component({
  selector: 'app-select-internal-trades',
  templateUrl: './select-internal-trades.component.html',
  styleUrls: ['./select-internal-trades.component.scss']
})
export class SelectInternalTradesComponent implements OnInit {
  isLoading = true;
  displayedColumns: string[] = ['select', 'employeeId', 'displayName', 'mail'];
  dataSourceInternalTrades!: MatTableDataSource<InternalTrade>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  selection = new SelectionModel<InternalTrade>(true, []);
  public internalTradesArray: Array<InternalTrade> = [];

  constructor(public internalTradesSelectionDialogRef: MatDialogRef<SelectInternalTradesComponent>, @Inject(MAT_DIALOG_DATA) public dialogData:
    {
      allTrades: InternalTrade[],
      selectedTrades: InternalTrade[]
    }) {
  }

  ngOnInit(): void {
    if (this.dialogData) {
      const internalTrades: InternalTrade[] = this.dialogData.allTrades;
      this.dataSourceInternalTrades = new MatTableDataSource(internalTrades);
      this.matSort.sort(({ disableClear: true}) as MatSortable);
      this.dataSourceInternalTrades.sort = this.matSort;
      if (this.dialogData.selectedTrades.length > 0) {
        this.dataSourceInternalTrades.data.forEach(row => {
          this.dialogData.selectedTrades.forEach(trade => {
            if (row.employeeId == trade.employeeId)
              this.selection.select(row)
          })
        });
      }
    }
  }

  onInsertToSoW() {
    this.selection.selected.forEach(item => {
      this.internalTradesArray.push(item);
    });
    this.internalTradesSelectionDialogRef.close(this.internalTradesArray);
  }

  public onCancel = () => {
  };

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceInternalTrades.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceInternalTrades.data.forEach(row => this.selection.select(row));
  }
}
