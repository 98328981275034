import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Contractor } from '@app/_models/contractor';
import { DocumentType } from '@app/_models/document-type';
import { RestService } from '@app/_services/rest.service';
import { SelectedFiles } from '@app/job-details/invoice-upload-dialog/invoice-upload-dialog.component';
import { AsyncSubject, Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { DocumentDetail } from '@app/_models/document-detail';
import { GlobalValues } from '@app/_services/global-values';
import { ADUserManager } from '@app/_models/user-manager-details-ad';
import { FunctionsService } from '@app/_services/functions.service';
import { FileTypes } from '@app/_helpers/constants';
import { DocumentTypeDetails } from '@app/_models/document-type-details';
import { DatePipe } from '@angular/common';
import { abnFormatValidator } from '@app/_validators/abn-format.validator';
import { TncAgreement } from '@app/_models/tncAgreement';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent implements OnInit {
  tnc!:TncAgreement;
  signeeName:string='';
  signeeTitle:string='';
  pdfSrc = '';
  userName: string = '';
  public form!: FormGroup;
  contractor!: Contractor;
  isLoading = false;
  fileReader = new FileReader();
  uploadProgress: number | null = 0;
  uploadSub: Subscription | null = new Subscription();
  file: File | null = null;
  base64Output!: string;
  public selectedFiles: SelectedFiles[] = [];
  private subscription: Subscription | undefined;
  documentTypes: DocumentType[] = [];
  minDate: string;
  signedDate: string;////!:Date;
  comment: string | null = null;
  selectedDate: string | null = null;
  documentTypeId!: number;
  userDetails!: ADUserManager;
  isPdf = false;
  documentTypeDetails = new DocumentTypeDetails();
  insurers!: Observable<any>;
  licenceClassifications!: Observable<any>;
  geoRegions!: Observable<any>;
  states!: Observable<any>;
  entityTypes!: Observable<any>;
  tradeClassifications!: Observable<any>;
  isChanged = false;
  documentComment: string = '';
  contractorNames: string[] = [];
  abnRegex = /^(?:\d{2} \d{3} \d{3} \d{3}|)$/;
  isDupicateTrade = false;

  constructor(
    private globalValues: GlobalValues,
    private restService: RestService,
    public functionsService: FunctionsService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DocumentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contractor: Contractor;
      userEmail: string;
    }
  ) {
    const today = new Date();
    this.signedDate = this.datePipe.transform(today,'yyyy-MM-dd')!; //today.toISOString().split('T')[0];
    this.minDate = this.datePipe.transform(today,'yyyy-MM-dd')!;//today.toISOString().split('T')[0];
    if (data) {
      this.contractor = data.contractor;
    }

    this.restService.getDocumentTypes().subscribe((result): any => {

      this.documentTypes = result;
    });
    this.getUserDetails();
  }

  ngOnInit(): void {
    this.documentTypeDetails.licenceName = "Some value should bind";
    this.restService.getContractors().subscribe({
      next: (data: Contractor[]) => {
        this.contractorNames = data.map((d) => d.tradingName?.toLowerCase());
      },
      error: (err: Error) => {
        Swal.fire(
          'Error: restService.getContractors<br/>' + err,
          '',
          'error'
        );
        this.isLoading = false;
      },
    });
    this.form = this.formBuilder.group({
      documentType: ['', [Validators.required]],
      documentFile: ['', [Validators.required]],
      comment: ['', [Validators.maxLength(499)]],
      signedDate: ['', [Validators.required]],
      tradingName: ['', [Validators.required]],
      signeeName: ['', [Validators.required,Validators.maxLength(255), Validators.pattern(/^[a-zA-Z\s]*$/)]],
      signeeTitle: ['', [Validators.required,Validators.maxLength(255)]],
      abn: ['', [Validators.required, Validators.pattern(this.abnRegex)]]
    });
    this.form.get('signedDate')?.setValue(this.signedDate);
    this.insurers = this.restService.getInsurers();
    this.licenceClassifications = this.restService.getLicenceClassifications();
    this.geoRegions = this.restService.getGeoRegions();
    this.states = this.restService.getStates();
    this.entityTypes = this.restService.getEntityTypes();
    this.tradeClassifications = this.restService.getTradeClassifications(
      this.contractor.contractorId
    );
  }
  duplicateValidator() {
    //let contractor = control.value;
    if (
      this.documentTypeDetails.tradeName &&
      this.contractorNames.includes(this.documentTypeDetails.tradeName?.toLowerCase()) &&
      this.contractor.tradingName.toLowerCase() != this.documentTypeDetails.tradeName?.toLowerCase()
    ) {
        this.isDupicateTrade=true;
    } else {
      this.isDupicateTrade = false;
    }
  }
  licenceRequired = [
    { id: true, text: 'Yes' },
    { id: false, text: 'No' },
  ];
  swmsRequired = [
    { id: true, text: 'Yes' },
    { id: false, text: 'No' },
  ];
  constructionInduction = [
    { id: 0, text: 'Not required' },
    { id: 1, text: 'Yes - Provided' },
    { id: 2, text: 'No - Not provided' },
  ];


  public toFilesBase64(
    files: File[],
    selectedFiles: SelectedFiles[]
  ): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          selectedFiles = selectedFiles?.filter(
            (f) => f?.name != files[i]?.name
          );
          selectedFiles.push({
            name: files[i]?.name,
            file: files[i],
            base64: reader?.result as string,
          });
          result.next(selectedFiles);
          if (files?.length === i + 1) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }

  onFileInput(files: FileList | null): void {
    if (files) {
      this.file = files.item(0);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onFileSelected(event: any) {
    if (event.target && event.target.files.length > 0 && FileTypes.includes(event.target.files[0].name.split('.').pop().toLowerCase())) {
      //remove any previous files from the array
      this.isPdf = event.target.files[0].name.split('.').pop().toLowerCase() == "pdf"
      this.selectedFiles.splice(0, this.selectedFiles.length);

      this.toFilesBase64(
        Array.from(event.target.files),
        this.selectedFiles
      ).subscribe((res: SelectedFiles[]) => {
        this.selectedFiles = res;
        this.pdfSrc = this.selectedFiles[0].base64!;
      });
    } else if (event.target && event.target.files.length > 0) {
      this.pdfSrc = '';
      this.form.controls.documentFile.setValue(null);
      Swal.fire(
        'Only PDF,PNG,JPG or GIF files please!',
        'Sorry, you can only upload pdf,png,gif or jpg document here. Please convert your document to a pdf,png,gif or jpg and upload. <br/><br/>Nice try though... :)',
        'error'
      );
    }
    else {
      this.pdfSrc = '';
    }
    this.isChanged = true;
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
  onchageofTrade()
  {
    this.duplicateValidator()
    if(this.contractor.tradingName.toLowerCase() != this.documentTypeDetails.tradeName?.toLowerCase())
      this.isChanged=true;
  }

  public async onSubmit() {
    if (this.selectedFiles.length > 0 && this.selectedFiles[0].file.size > 10485760){
      Swal.fire({
        title:'<strong>File size larger than 10MB!</strong><br/>The file to be uploaded is larger than 10MB, please change the file size smaller than 10MB, then upload again.',
        icon:'error'
      }).then(() => {return;}); return;
    }
    else {
      this.isLoading = true;
      Swal.fire({
        title: 'Are you sure you want to Upload this Document?',
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
        confirmButtonColor: '#007bff',
        denyButtonColor: '#dc3545',
        focusDeny: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        preConfirm: (retVal) => {
          if (!retVal) {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i> You need to select a Status'
            );
          } else {
            return retVal;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.selectedFiles.length > 0) {
            let fileType: string | null = '';
            fileType = this.selectedFiles[0].file.type;
            let base64String: string | null = '';
            base64String = this.selectedFiles[0].base64
              ? this.selectedFiles[0].base64
                ?.toString()
                .replace('data:', '')
                .replace(/^.+,/, '')
              : '';

            if (base64String == '' || base64String == 'null') {
              Swal.fire(
                'Failed!',
                'Upload failed. Invalid or corrupted file!',
                'error'
              );
            } else {
              this.isLoading = true;
              try {
                let documentTypeDetails = new DocumentTypeDetails()
                let documentDetails = new DocumentDetail();
                documentDetails.contractorId = this.contractor.contractorId;
                documentDetails.documentTypeId = Number(this.documentTypeId);
                documentDetails.documentData = base64String;
                documentDetails.fileType = fileType;
                documentDetails.addedById = this.userDetails.employeeID;
                documentDetails.comment = this.documentComment;
                documentTypeDetails.tradeName = this.documentTypeDetails.tradeName;
                documentTypeDetails.entryName = this.contractor.companyName;
                documentTypeDetails.geoRegionId =  this.documentTypeDetails.geoRegionId!= null ? Number(this.documentTypeDetails.geoRegionId) : null;
                documentTypeDetails.address = this.documentTypeDetails.address;
                documentTypeDetails.suburb = this.documentTypeDetails.suburb;
                documentTypeDetails.postcode = this.documentTypeDetails.postcode;
                documentTypeDetails.stateId = this.documentTypeDetails.stateId!= null ? Number(this.documentTypeDetails.stateId) : null;
                documentTypeDetails.mainContactEmail = this.documentTypeDetails.mainContactEmail;
                documentTypeDetails.mainContactName = this.documentTypeDetails.mainContactName;
                documentTypeDetails.mainContactPhone = this.documentTypeDetails.mainContactPhone;
                documentTypeDetails.accountContactEmail = this.documentTypeDetails.accountContactEmail;
                documentTypeDetails.accountContactName = this.documentTypeDetails.accountContactName;
                documentTypeDetails.accountContactPhone = this.documentTypeDetails.accountContactPhone;
                documentTypeDetails.mainTradeCategoryId = this.contractor.mainTradeCategoryId!= null ? Number(this.contractor.mainTradeCategoryId) : null;
                documentTypeDetails.entityTypeId = this.contractor.entityTypeId!= null ? Number(this.contractor.entityTypeId) : null;
                documentTypeDetails.abn = this.contractor.abn;
                documentTypeDetails.acn = this.contractor.acn;
                if (documentDetails.documentTypeId == 1) {
                  documentTypeDetails.licenceRequired = this.documentTypeDetails.licenceRequired != null ? Boolean(this.documentTypeDetails.licenceRequired) : null;
                  documentTypeDetails.licenceName = this.documentTypeDetails.licenceName;
                  documentTypeDetails.licenceClassificationId =  this.documentTypeDetails.licenceClassificationId != null ? Number(this.documentTypeDetails.licenceClassificationId) : null;
                  documentTypeDetails.licenceNumber = this.documentTypeDetails.licenceNumber;
                  documentTypeDetails.licenceExpiryDate = this.documentTypeDetails.licenceExpiryDate;
                  documentTypeDetails.licenceConditions = this.documentTypeDetails.licenceConditions;
                  documentDetails.expireDate = this.documentTypeDetails.licenceExpiryDate;

                }
                else if (documentDetails.documentTypeId == 2) {
                  documentTypeDetails.plInsurerId = this.documentTypeDetails.plInsurerId != null ? Number(this.documentTypeDetails.plInsurerId) : null;
                  documentTypeDetails.plPolicyNumber = this.documentTypeDetails.plPolicyNumber;
                  documentTypeDetails.plExpiryDate = this.documentTypeDetails.plExpiryDate;
                  documentTypeDetails.plSumInsured = this.documentTypeDetails.plSumInsured != null ? Number(this.documentTypeDetails.plSumInsured) : null;
                  documentDetails.expireDate = this.documentTypeDetails.plExpiryDate;

                }
                else if (documentDetails.documentTypeId == 3) {
                  documentTypeDetails.wcsaInsurerId = this.documentTypeDetails.wcsaInsurerId != null ? Number(this.documentTypeDetails.wcsaInsurerId) : null;
                  documentTypeDetails.policyNumber = this.documentTypeDetails.policyNumber;
                  documentTypeDetails.policyExpiryDate = this.documentTypeDetails.policyExpiryDate;
                  documentTypeDetails.policySumInsured = this.documentTypeDetails.policySumInsured != null ? Number(this.documentTypeDetails.policySumInsured) : null;
                  documentDetails.expireDate = this.documentTypeDetails.policyExpiryDate;
                }
                else if (documentDetails.documentTypeId == 4) {
                  documentTypeDetails.constructionInduction = this.documentTypeDetails.constructionInduction != null ? Number(this.documentTypeDetails.constructionInduction) : null;
                  documentDetails.expireDate = this.documentTypeDetails.documentDetail != null ? this.documentTypeDetails.documentDetail.expireDate : null;
                }
                else if (documentDetails.documentTypeId == 5) {
                  documentTypeDetails.swmsRequired = this.documentTypeDetails.swmsRequired != null ? Boolean(this.documentTypeDetails.swmsRequired) : null;
                  documentTypeDetails.swmsProvidedDate = this.documentTypeDetails.swmsProvidedDate;
                  documentDetails.expireDate = this.documentTypeDetails.swmsProvidedDate;
                }
                else if (documentDetails.documentTypeId == 8) {
                  let tnc = this.tnc;
                  tnc.dateSigned = new Date(this.signedDate);//new Date();
                  tnc.signeeName = this.form.controls.signeeName.value;//this.signeeName;
                  tnc.signeeTitle = this.form.controls.signeeTitle.value;//this.signeeTitle;
                  tnc.signedPdfData = this.pdfSrc//this.selectedFiles[0].base64
                  ? this.pdfSrc//this.selectedFiles[0].base64
                    ?.toString()
                    .replace('data:', '')
                    .replace(/^.+,/, '')
                  : '';
                  this.restService.updateTncAgreement(tnc.id, tnc).subscribe({
                    next: (x) => {
                      this.tnc= x;
                      Swal.fire('Terms and Conditions uploaded successfully and marked as signed!', '', 'success').then(() => {               
                        this.pdfSrc='';
                        this.isLoading = false;
                        window.location.reload();
                      })
                    },
                    error: (err) => {
                      this.isLoading = false;
                      Swal.fire(
                        'Error: restService.updateTncAgreement<br/>' + err,
                        '',
                        'error'
                      );
                    },
                  });
                }
                else {
                  documentDetails.expireDate = this.documentTypeDetails.documentDetail != null ? this.documentTypeDetails.documentDetail.expireDate : null;
                }
                if (documentDetails.documentTypeId != 8){
                  documentTypeDetails.documentDetail = documentDetails;
                  this.restService.addNewDocumentDetail(documentTypeDetails).subscribe({
                    next: (x) => {
                      this.dialogRef.close("Success");
                      this.isLoading = false;
                    },
                    error: (err) => {
                      this.isLoading = false;
                      Swal.fire(
                        'Error: restService.addNewDocumentDetail<br/>' + err,
                        '',
                        'error'
                      );
                    },
                  });
                }
              } catch (error: any) {
                this.isLoading = false;
                Swal.fire(
                  'Error: documentUploadDialogRef.onSubmit(): Catch Error<br/>' +
                  error,
                  '',
                  'error'
                );
              }
            }
          }
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  onDateChange(event: any) {
    this.isChanged = true;
    if (event.target.id == 'signedDate'){
      this.signedDate = event.target.value;
    }
    else {
      this.selectedDate = event.target.value;
    }
  }

  onDocumentTypeChange(event: any) {
    this.pdfSrc='';
    if (event.target.value == 8) {
      this.restService.getTncAgreementByContractorId(this.contractor.contractorId).subscribe({
        next: (x) => {
          if (x && x.length>0){
            const tncs = x.sort((a, b) => b.versionNo! - a.versionNo!);
            this.tnc= tncs[0];
            if (!this.tnc){
              Swal.fire(
                '<strong>No T&Cs agreement sent!</strong><br/> Please make sure the Contractor is active, and send a new Terms and Conditions agreement first, then upload from here again.',
                '',
                'error'
              );
            } 
            else if(this.tnc.dateSigned && this.tnc.signeeName){
              Swal.fire(
                '<strong>Already signed the T&Cs!</strong><br/> This Contractor has already signed the T&Cs agreement on '+ this.datePipe.transform(this.tnc.dateSigned,'dd/MMM/yyyy')+', you can send a new Terms and Conditions, then upload from here again if you want to sign a new version of T&Cs agreement.',
                '',
                'error'
              );
            }
            else{
              this.documentTypeId = event.target.value;
              this.documentTypeDetails.tradeName = this.contractor.tradingName;
            }
          }
          else {
            Swal.fire(
              '<strong>No T&Cs agreement sent!</strong><br/> Please make sure the Contractor is active, and send a new Terms and Conditions agreement first, then upload from here again.',
              '',
              'error'
            );
          }
        },
        error: (err: Error) => {
          Swal.fire(
            'Error: restService.getTncAgreementByContractorId<br/>' + err,
            '',
            'error'
          );
          this.isLoading = false;
        },
      });
    }
    else { 
      this.documentTypeId = event.target.value;
      this.isLoading = true;
      this.restService.getDetailsForDocumentByDocumentTypeId(this.documentTypeId, this.contractor.contractorId).subscribe(x => {
        this.documentComment = '';
        this.documentTypeDetails = x;
        this.documentTypeDetails.tradeName = this.contractor.tradingName;
        this.documentTypeDetails.policyExpiryDate = x.policyExpiryDate != null ? x.policyExpiryDate.toString().split('T')[0] : null;
        this.documentTypeDetails.plExpiryDate = x.plExpiryDate != null ? x.plExpiryDate.toString().split('T')[0] : null;
        this.documentTypeDetails.licenceExpiryDate = x.licenceExpiryDate != null ? x.licenceExpiryDate.toString().split('T')[0] : null;
        this.documentTypeDetails.swmsProvidedDate = x.swmsProvidedDate != null ? x.swmsProvidedDate.toString().split('T')[0] : null;
        let date5 = x.documentDetail != null ? x.documentDetail?.expireDate : null;
        if (this.documentTypeDetails.documentDetail != null)
          this.documentTypeDetails.documentDetail!.expireDate = date5 != null ? x.documentDetail?.expireDate.toString().split('T')[0] : null;
        this.documentTypeDetails.licenceRequired = this.contractor.licenceRequired;
        this.documentTypeDetails.swmsRequired = this.contractor.swmsRequired
        this.pdfSrc = this.documentTypeDetails.documentDetail?.documentData != null ? "data:" + this.documentTypeDetails.documentDetail?.fileType + ';base64,' + this.documentTypeDetails.documentDetail?.documentData : '';
        this.isPdf = this.documentTypeDetails.documentDetail?.fileType == "application/pdf";
        this.documentComment = (this.documentTypeDetails.documentDetail != null && this.documentTypeDetails.documentDetail.comment != null) ? this.documentTypeDetails.documentDetail.comment : this.documentComment;
        this.isLoading = false;
        this.isChanged = false;
      });
    }
  }

  onChange() {
    this.isChanged = true;
  }

  deleteDocument() {
    this.restService.deleteDocumentById(this.documentTypeDetails.documentDetail!.documentId).subscribe((response) => {
      let result = response.message;
      if (result == "Deleted Successfully") {
        Swal.fire('Document deleted successfully', '', 'success').then(() => {
          this.isLoading = false;
          window.location.reload();
        })
      }
      else {
        Swal.fire('Document not found', '', 'warning').then(() => {
          this.isLoading = false;
          window.location.reload();
        })
      }
    })
  }
  getUserDetails() {
    return this.restService.getADUserByEmail(this.globalValues.userEmailId).subscribe((userDetails) => {
      this.userDetails = userDetails;
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

}