<div class="main-container">
  <h1>NIB/ Mk3 Internal Trades</h1>

  <!-- CSS loading animation container -->
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="main-table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-striped">
      <ng-container matColumnDef="employeeId">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>EmployeeID</th>
        <td mat-cell style="text-align: right!important; padding-right: 50px;" *matCellDef="let internalTrades">
          {{internalTrades.employeeId}}</td>
      </ng-container>
      <ng-container matColumnDef="displayName">
        <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>Display Name</th>
        <td mat-cell *matCellDef="let internalTrades">{{internalTrades.displayName}}</td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th class="w-30" mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let internalTrades">{{internalTrades.title}}</td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
        <td mat-cell *matCellDef="let internalTrades"><a
            href="tel:{{internalTrades.mobile}}">{{internalTrades.mobile}}</a></td>
      </ng-container>
      <ng-container matColumnDef="mail">
        <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let internalTrades"><a
            href="mailto:{{internalTrades.mail}}">{{internalTrades.mail}}</a></td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let internalTrades">{{internalTrades.state}}</td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell style="white-space: nowrap" *matCellDef="let internalTrades">
          <button mat-raised-button class="btn btn-sm btn-success"
            (click)="internalTradeDetails(internalTrades.employeeId)">Details</button>&nbsp;&nbsp;
        </td>
      </ng-container>
      <ng-container matColumnDef="send">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell style="white-space: nowrap" *matCellDef="let internalTrades">
          <button mat-raised-button class="btn btn-sm btn-primary"
            (click)="newWorkInstructions(internalTrades.employeeId)">Send Instructions</button>&nbsp;&nbsp;
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator *ngIf="dataSource" [pageSize]="dataSource.data.length"
      [pageSizeOptions]="[15, 50, 100, dataSource.data.length]" showFirstLastButtons class="mat-paginator">
    </mat-paginator>
  </div>
</div>
