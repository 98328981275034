<h1 mat-dialog-title style="color: white; background-color:var(--nibblue); text-align: center;">Select Scope of Works
  to send for RefNo: {{this.refNo}}</h1>

<form autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
  <mat-dialog-content text-align="middle" style="width: 100%; max-height: 550px;">
    <div class="mat-eleation-z8">
      <table mat-table [dataSource]="dataSourceWorkItems" matSort class="mat-elevation-z8 table-striped"
        style="width: 100%;">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="w-2" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="sowId">
          <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>SoW ID</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.sowId}}</td>
        </ng-container> -->
        <ng-container matColumnDef="itemRef">
          <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>SoW ItemRef</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.itemRef}}</td>
        </ng-container>
        <ng-container matColumnDef="scope">
          <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Scope</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.scope}}</td>
        </ng-container>
        <ng-container matColumnDef="area">
          <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Area</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.area}}</td>
        </ng-container>
        <ng-container matColumnDef="tradeName">
          <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Trade</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.tradeName}}</td>
        </ng-container>
        <ng-container matColumnDef="itemCode">
          <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Item Code</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.itemCode}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th class="w-50" mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.description}}<div
              *ngIf="workItems.descriptionMk3ForENData">{{workItems.descriptionMk3ForENData}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="additionalInfo">
          <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>Additional Info</th>
          <td mat-cell *matCellDef="let workItems">{{workItems.additionalInfo}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

      </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" class="btn mat-raised-button btn-sm btn-primary" (click)="onInsertToSoW()"
      [disabled]="this.selection.selected.length == 0">Insert to Scope of Works</button>
    <button mat-button [mat-dialog-close]="true" mat-dialog-close type="button" mat-raised-button
      class="btn mat-button-base" (click)="onCancel()">Cancel</button>
  </mat-dialog-actions>
</form>