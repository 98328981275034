export const environment = {
  production: true,
  //contractorAPIMainUrl: 'https://localhost:44346/api/'//Local
  //contractorAPIMainUrl: 'http://mk3-web:8007/api/',//IIS Web API for Data Access
  contractorAPIMainUrl: 'https://wgil-api-management.azure-api.net/contractor/api/',//Azure Web API for Data Access
  commonAPIMainUrl: 'https://wgil-api-management.azure-api.net/common/api/',//Azure Web API for Data Access
  casesApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/WgicasesAlls',
  wgilAPIBaseUrl: 'https://wgil-api-management.azure-api.net/v1/api/',
  sowApiUrl: 'https://wgil-api-management.azure-api.net/sow/api/',
  claimContactApiUrl:
  //'https://wgil-api-management.azure-api.net/v1/api/ClaimContacts/Contacts', //Azure Web API for the list of Claim Contacts'
  'https://wgil-api-management.azure-api.net/v1/api/ClaimContacts/ContactsList', //Azure Web API for the list of Claim Contacts'

  //commonAPIMainUrl: 'http://mk3-web:8005/api/'//IIS Web API for Data Access
  //contractorAPIMainUrl: 'https://wgil-api-management.azure-api.net/v1/api/WgicasesLatests'//Azure Web API for Data Access
};
