import { DatePipe, Time } from '@angular/common';
import { DocumentDetailsComponent } from '../contractor-details/document-details/document-details.component';
import { SelectedFiles } from '@app/job-details/invoice-upload-dialog/invoice-upload-dialog.component';
import { FileTypes, PdfType } from '@app/_helpers/constants';
import { Component, Inject, Input, OnInit, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { Contact } from '@app/_models/contact';
import { Contractor } from '@app/_models/contractor';
import { Case } from '@app/_models/case';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { AsyncSubject, first, map, Observable, throwError } from 'rxjs';
import { Font, jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { InternalTrade } from '@app/_models/internal-trade';
import { Email } from '@app/_models/email';
import { EmailRecord } from '@app/_models/email-record';
import Swal from 'sweetalert2';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { DatumNote } from '@app/_models/datumNote';
import { WorkItem } from '@app/_models/work-item';
import { SowSelectionDialogComponent } from '@app/sow-selection-dialog/sow-selection-dialog.component';
import { JobHistory } from '@app/_models/job-history';
import { SelectInternalTradesComponent } from '@app/select-internal-trades/select-internal-trades.component';
import { ClaimContact } from '@app/_models/claimContact';
import { AlertType } from '@app/_models/alert';
import { InstructionType } from '@app/_models/instructionType';


interface PdfDetails {
  instructionTypeId: number;
  instructionItemId: number | null;
  officeShortName: string;
  officeFullName: string;
  reportShortName: string;
  pdfTitle: string;
  phone: string;
  email: string;
  website: string;
  logoHeight: number;
  logoWidth: number;
  logoTop: number;
  logoImgSrc: string;
  footerLogos: boolean;
  fromEmailAddress: string;
}

interface SiteOptions {
  id: number;
  name: string;
}

class CustomerContact {
  id: number = 0;
  number: string = '';
  name: string = '';
  description: string = '';
  claimContactRole: string = '';
}

@Component({
  selector: 'app-generate-document-dialog',
  templateUrl: './generate-document-dialog.component.html',
  styleUrls: ['./generate-document-dialog.component.scss'],
})
export class GenerateDocumentDialogComponent implements OnInit {
  public form!: FormGroup;
  public selectedFiles: SelectedFiles[] = [];
  @ViewChild('attachmentDialog') attachmentDialog!: TemplateRef<any>;
  @ViewChild('scopeWorks') scopeWorks!: TemplateRef<any>;
  scopeWorksContent:string='';
  attachmentExceed20MB:string='';
  attachSameFileName:string='';
  isNewAttachment:boolean=true;
  currentFile:SelectedFiles[]=[];
  isPdf = false;
  isChanged = false;
  pdfSrc = '';
  title!: string;
  isLoading = true;
  contractor!: Contractor;
  case!: Case;
  internalTrade!: InternalTrade;
  additionalInternalTradesArray: Array<InternalTrade> = [];
  refNo!: number;
  instructionTypes!: Observable<any>;
  instructionTypeId: string = '';
  consultantPhone: string = '';
  contractorContact = <Contact>{};
  documentTypeQuote: boolean = false;
  isInternalInstructions: boolean = false;
  description: string = '';
  companyName: string = '';
  companyEmail: string = '';
  companyPhone: string = '';
  emailRecord!: EmailRecord;
  riskAddress: string = '';
  claimOwnerEmails: string = '';
  internalTradeEmailGreeting: string = '';
  claimContactsArray!: ClaimContact[];
  isChecked: boolean = false;
  otherContact: string ='';

  @Input() max: any;

  pdfDetails: PdfDetails = {
    instructionTypeId: 0,
    instructionItemId: null,
    officeShortName: '',
    officeFullName: '',
    reportShortName: '',
    pdfTitle: '',
    phone: '',
    email: '',
    website: '',
    logoHeight: 0,
    logoWidth: 0,
    logoTop: 0,
    logoImgSrc: '',
    footerLogos: false,
    fromEmailAddress: '',
  };

  siteOptionSelected: number = 1;
  today = moment().toDate();
  inspectionDate: string = '';
  inspectionTime: Time = { hours: 0, minutes: 0 };

  radioButtons: SiteOptions[] = [
    { id: 1, name: 'Select Date/ Time' },
    { id: 2, name: 'Organise Directly' },
    { id: 3, name: 'Custom Instructions' },
  ];

  customerContactsArray: Array<CustomerContact> = [];
  customerContacts!: [id: number, number: '', name: '', description: '', type: ''];
  customerContactType: number = -1;

  constructor(
    public dialogRef: MatDialogRef<GenerateDocumentDialogComponent>,
    public dialogAttachmentRef: MatDialogRef<any>,
    public dialogScopeWorksRef: MatDialogRef<any>,
    private restService: RestService,
    private alertService: AlertService,
    private router: Router,
    public dialog: MatDialog,
    public dialogAttachment: MatDialog,
    public dialogScopeWorks: MatDialog,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    public sowSelectionDialogRef: MatDialogRef<SowSelectionDialogComponent>,
    public internalTradesSelectionDialogRef: MatDialogRef<SelectInternalTradesComponent>,
    public functionsService: FunctionsService,
    private msalService: MsalService,
    //public appComponent: AppComponent,
    public documentUploadDialogRef: MatDialogRef<DocumentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contractor: Contractor;
      case: Case;
      internalTrade: InternalTrade;
      userName: string;
      userEmail: string;
    }
  ) {
    if (this.data.case) {
      this.case = data.case;
      this.getClaimOwnerDetils(this.case.refNo);
      this.riskAddress = (
        this.functionsService.blankIfNull(this.case.situationStreet) +
        ' ' +
        this.functionsService.blankIfNull(this.case.situationSuburb) +
        ' ' +
        this.functionsService.blankIfNull(this.case.state) +
        ' ' +
        this.functionsService.blankIfNull(this.case.postCode)
      )?.trim();
    }

    if (this.data.contractor) {
      this.contractor = data.contractor;
      this.getMaxInstructionItemId(Number(this.instructionTypeId)).subscribe({
        next: (maxId: number) => {
          this.setNextInstructionItemId(maxId);
          this.companyName = this.contractor.tradingName
            ? this.contractor.tradingName
            : '';
          this.companyEmail = this.contractor.mainContactEmail
            ? this.contractor.mainContactEmail
            : '';
          this.companyPhone = this.contractor.mainContactPhone
            ? this.contractor.mainContactPhone
            : '';
          this.pdfDetails.pdfTitle = (
            'Work Instruction Sheet ' +
            (this.pdfDetails.instructionItemId
              ? this.pdfDetails.instructionItemId?.toString()
              : '')
          ).trimEnd();
          this.title = this.pdfDetails.pdfTitle + ' to ' + this.companyName;
        },
      });
    }

    if (this.data.internalTrade) {
      this.isInternalInstructions = true;
      this.internalTrade = data.internalTrade;
      this.getMaxInstructionItemId(Number(this.instructionTypeId)).subscribe({
        next: (maxId: number) => {
          this.setNextInstructionItemId(maxId);
          this.companyName = this.internalTrade.displayName
            ? this.internalTrade.displayName
            : '';
          this.companyEmail = this.internalTrade.mail
            ? this.internalTrade.mail
            : '';
          this.companyPhone = this.internalTrade.mobile
            ? this.internalTrade.mobile
            : '';
          this.pdfDetails.pdfTitle = (
            'Work Instruction Sheet ' +
            (this.pdfDetails.instructionItemId
              ? this.pdfDetails.instructionItemId?.toString()
              : '')
          ).trimEnd();
          this.title = this.pdfDetails.pdfTitle + ' to ' + this.companyName;
        },
      });
    }

    if (Number.isInteger(this.case.consultantId)) {
      this.restService
        .getADUser(this.case.consultantId)
        .subscribe((userDetails) => {
          this.consultantPhone = userDetails[0].mobile;
        });
    }
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required]),
      scopeWorksContent: new FormControl('',),
      id: new FormControl(1),
      instructionTypeId: new FormControl(0, [
        Validators.required,
        Validators.min(1),
      ]),
      siteAttendOptions: new FormControl(1, [Validators.required]),
      inspectionDate: new FormControl(new Date()),
      inspectionTime: new FormControl('00:00 AM'),
      customerContactType: new FormControl(-1, [
        Validators.required,
        Validators.min(0),
      ]),
      customSiteAttendInstructions: new FormControl(''),
      jobCost: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(
          /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
        ),
      ]),
      emailBody: new FormControl('', [
        Validators.required,
        Validators.maxLength(2000),
      ]),
    });
    if (this.contractor) {
      this.restService
        .getContactDetails(this.contractor.contractorId)
        .subscribe((data: Contact) => {
          this.contractorContact = { ...data };
        });
    }

    let receiverName = this.isInternalInstructions
      ? this.internalTrade?.displayName
      : this.contractor?.mainContactName;
    let documentType =
      this.instructionTypeId == '1' ||
      this.instructionTypeId == '3' ||
      this.instructionTypeId == '5'
        ? 'instruction sheet'
        : 'quote request';
    this.instructionTypes = this.restService.getInstructionTypes(
      this.isInternalInstructions
    );

    if (this.case.clientId === 3 || this.case.clientId === 9){
      this.instructionTypes = this.instructionTypes.pipe(map((instructions:any) => instructions.filter((x:InstructionType) => x.id !== 3 && x.id !== 5 && x.id !== 4)));
    }

    this.getCustomerContacts();
    this.documentTypeQuote = false;
    this.pdfDetails.officeShortName = 'NIB';
    this.pdfDetails.officeFullName = 'National Insurance Builders';
    this.pdfDetails.reportShortName = 'NIB Work Instruction';
    this.pdfDetails.pdfTitle = (
      'Work Instruction Sheet ' +
      (this.pdfDetails.instructionItemId
        ? this.pdfDetails.instructionItemId?.toString()
        : '')
    ).trimEnd();
    this.pdfDetails.phone = '02 9839 3830';
    this.pdfDetails.email = 'nib@nibaustralia.com.au';
    this.pdfDetails.website = 'www.nibaustralia.com.au';
    this.pdfDetails.logoHeight = 40;
    this.pdfDetails.logoWidth = 40;
    this.pdfDetails.logoTop = 10;
    this.pdfDetails.logoImgSrc = 'assets/nib-logo.png';
    this.pdfDetails.footerLogos = true;
    this.pdfDetails.fromEmailAddress = 'trades@nibaustralia.com.au';
    this.form.controls.siteAttendOptions.setValidators(Validators.required);

    if (this.internalTrade && this.documentTypeQuote)
      this.form.controls['emailBody'].setValue(
        'Hello ' +
          receiverName +
          ',\r\n\r\nPlease find attached NIB ' +
          documentType +
          ' for ' +
          this.riskAddress +
          '.\r\nWe kindly request that you respond to this email with your quotation. Your prompt attention to this matter is highly appreciated' +
          '.\r\n\r\nRegards\r\n' +
          this.data.userName
      );
    else if (this.internalTrade && !this.documentTypeQuote) {
      this.internalTradeEmailGreeting = 'Hello ' + receiverName + ',';
      this.form.controls['emailBody'].setValue(
        this.internalTradeEmailGreeting +
          '\r\n\r\nPlease find attached NIB ' +
          documentType +
          ' for ' +
          this.riskAddress +
          '.\r\n\r\nFollowing MUST be completed:' +
          '\r\n\r\n<b>Take 5 for each claim</b>' +
          '.\r\n“sign in” when you arrive and “sign out” when leaving the property.' +
          '<b>\r\nWork Instruction sign off sheets</b>' +
          '.\r\nTradesman to sign and date with insured signature at completion of works emailed to <a href=mailto:nib@nibaustralia.com.au>nib@nibaustralia.com.au</a> and to the assessor.' +
          '<b>\r\nPhotos on OneDrive</b>' +
          '.\r\nBefore and after works completed' +
          '.\r\n\r\nRegards\r\n' +
          this.data.userName
      );
    } else if (!this.internalTrade && this.documentTypeQuote)
      this.form.controls['emailBody'].setValue(
        'Hello ' +
          receiverName +
          ',\r\n\r\nPlease find attached ' +
          documentType +
          ' from ' +
          this.pdfDetails.officeShortName +
          ' for ' +
          this.riskAddress +
          '.\r\nWe kindly request that you respond to this email with your quotation. Your prompt attention to this matter is highly appreciated' +
          '.\r\n\r\nRegards\r\n' +
          this.data.userName
      );
    else if (!this.internalTrade && !this.documentTypeQuote)
      this.form.controls['emailBody'].setValue(
        'Hello ' +
          receiverName +
          ',\r\n\r\nPlease find attached ' +
          documentType +
          ' from ' +
          this.pdfDetails.officeShortName +
          ' for ' +
          this.riskAddress +
          '.\r\n\r\nPlease ensure the following:' +
          '\r\n\r\n<b>1. Provide us with the date of commencement of works and expected end date by return email ‘Reply All’ ' +
          '.\r\n2. Notify us when works are completed, making sure both signatures and date are on the work instruction' +
          '.\r\n3. Job number and assessor name is included on the invoice' +
          '.\r\n4. You submit your invoice by responding to this email (' + this.pdfDetails.email + ').</b>' +
          '\r\n\r\nPlease note, the sign off sheet must be <b><u>signed by the insured at the completion of works and submitted along with the invoice.</u></b> We cannot release payment until this is done.' +
          '\r\n\r\n<b><u>Restorers</u> please note:' +
          '\r\nWe require that you submit <u>on first day</u> of attendance' +
          '\r\n1. Photos of installed equipment to each room' +
          '\r\n2. Photos showing overall room/s where equipment is installed' +
          '\r\n3. Inventory of equipment installed; moisture readings and works completed on initial site visit' +
          '\r\n4. We kindly request that you send your invoice by responding to this email (' + this.pdfDetails.email + ')' +
          '\r\nPlease ensure our reference number is in the subject line of any email communications.</b>' +
          '\r\n\r\nRegards\r\n' +
          this.data.userName
      );
  }

  close() {
    this.dialogRef.close();
  }

  getMaxInstructionItemId(instructionTypeId: number): Observable<number> {
    return this.restService.getMaxInstructionItemId(
      this.case.refNo,
      this.contractor?.contractorId,
      this.internalTrade?.employeeId,
      instructionTypeId
    );
  }

  setNextInstructionItemId(id: number) {
    if (id == -1) this.pdfDetails.instructionItemId = null;
    else if (id == 0) this.pdfDetails.instructionItemId = 2;
    else this.pdfDetails.instructionItemId = id + 1;
  }

  onChangeInstructionType(event?: any) {
    if (event) {
      const id = Number(event?.target.value.split(':')[1]?.trim());
      this.pdfDetails.instructionTypeId = id;
      this.instructionTypeId = id.toString();

      this.getMaxInstructionItemId(id).subscribe({
        next: (maxId: number) => {
          this.setNextInstructionItemId(maxId);
          switch (this.pdfDetails.instructionTypeId) {
            case 1: {
              this.documentTypeQuote = false;
              this.pdfDetails.officeShortName = 'NIB';
              this.pdfDetails.officeFullName = 'National Insurance Builders';
              this.pdfDetails.reportShortName = 'NIB Work Instruction';
              this.pdfDetails.pdfTitle = (
                'Work Instruction Sheet ' +
                (this.pdfDetails.instructionItemId
                  ? this.pdfDetails.instructionItemId?.toString()
                  : '')
              ).trimEnd();
              this.pdfDetails.phone = '02 9839 3830';
              this.pdfDetails.email = 'trades@nibaustralia.com.au';
              this.pdfDetails.website = 'www.nibaustralia.com.au';
              this.pdfDetails.logoHeight = 40;
              this.pdfDetails.logoWidth = 40;
              this.pdfDetails.logoTop = 10;
              this.pdfDetails.logoImgSrc = 'assets/nib-logo.png';
              this.pdfDetails.footerLogos = true;
              this.pdfDetails.fromEmailAddress = 'trades@nibaustralia.com.au';
              this.form.controls.siteAttendOptions.setValidators(
                Validators.required
              );

              if(!this.isInternalInstructions)
              {
              this.form.controls.jobCost.setValidators([
                Validators.required,
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
            }
            else{
              this.form.controls.jobCost.setValue('');
              this.form.controls.jobCost.setValidators([
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
            }
            this.form.controls.jobCost.updateValueAndValidity();
            break;
          }
            case 2: {
              this.documentTypeQuote = true;
              this.pdfDetails.officeShortName = 'NIB';
              this.pdfDetails.officeFullName = 'National Insurance Builders';
              this.pdfDetails.reportShortName = 'NIB Quote Request';
              this.pdfDetails.pdfTitle = (
                'Quote Request ' +
                (this.pdfDetails.instructionItemId
                  ? this.pdfDetails.instructionItemId?.toString()
                  : '')
              ).trimEnd();
              this.pdfDetails.phone = '02 9839 3830';
              this.pdfDetails.email = 'trades@nibaustralia.com.au';
              this.pdfDetails.website = 'www.nibaustralia.com.au';
              this.pdfDetails.logoHeight = 40;
              this.pdfDetails.logoWidth = 40;
              this.pdfDetails.logoTop = 10;
              this.pdfDetails.logoImgSrc = 'assets/nib-logo.png';
              this.pdfDetails.footerLogos = true;
              this.pdfDetails.fromEmailAddress = 'trades@nibaustralia.com.au';
              this.form.controls.siteAttendOptions.setValidators(null);
              this.form.controls.jobCost.setValue('');
              this.form.controls.jobCost.setValidators([
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
              this.form.controls.jobCost.updateValueAndValidity();
              break;
            }
            case 3: {
              this.documentTypeQuote = false;
              this.pdfDetails.officeShortName = 'RR';
              this.pdfDetails.officeFullName = 'Revival Restorations';
              this.pdfDetails.pdfTitle = (
                'RR Instruction Sheet ' +
                (this.pdfDetails.instructionItemId
                  ? this.pdfDetails.instructionItemId?.toString()
                  : '')
              ).trimEnd();
              this.pdfDetails.reportShortName = 'RR Work Instruction';
              this.pdfDetails.phone = '02 9839 3850';
              this.pdfDetails.email = 'trades@revivalrestorations.com.au';
              this.pdfDetails.website = '';
              this.pdfDetails.logoHeight = 30;
              this.pdfDetails.logoWidth = 141.3;
              this.pdfDetails.logoTop = 15;
              this.pdfDetails.logoImgSrc = 'assets/rr-logo.png';
              this.pdfDetails.footerLogos = false;
              this.pdfDetails.fromEmailAddress =
                'trades@revivalrestorations.com.au';
              this.form.controls.siteAttendOptions.setValidators(
                Validators.required
              );

              if(!this.isInternalInstructions)
                {
              this.form.controls.jobCost.setValidators([
                Validators.required,
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
            }
            else{
              this.form.controls.jobCost.setValue('');
              this.form.controls.jobCost.setValidators([
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
            }
            this.form.controls.jobCost.updateValueAndValidity();
              break;
            }
            case 4: {
              this.documentTypeQuote = true;
              this.pdfDetails.officeShortName = 'RR';
              this.pdfDetails.officeFullName = 'Revival Restorations';
              this.pdfDetails.reportShortName = 'RR Quote Request';
              this.pdfDetails.pdfTitle = (
                'Quote Request ' +
                (this.pdfDetails.instructionItemId
                  ? this.pdfDetails.instructionItemId?.toString()
                  : '')
              ).trimEnd();
              this.pdfDetails.phone = '02 9839 3850';
              this.pdfDetails.email = 'trades@revivalrestorations.com.au';
              this.pdfDetails.website = '';
              this.pdfDetails.logoHeight = 30;
              this.pdfDetails.logoWidth = 141.3;
              this.pdfDetails.logoTop = 15;
              this.pdfDetails.logoImgSrc = 'assets/rr-logo.png';
              this.pdfDetails.footerLogos = false;
              this.pdfDetails.fromEmailAddress =
                'trades@revivalrestorations.com.au';
              this.form.controls.siteAttendOptions.setValidators(null);
              this.form.controls.jobCost.setValue('');
              this.form.controls.jobCost.setValidators([
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
              this.form.controls.jobCost.updateValueAndValidity();
              break;
            }
            case 5: {
              this.documentTypeQuote = false;
              this.pdfDetails.officeShortName = 'Mk3';
              this.pdfDetails.officeFullName = 'Mk3 Pty Ltd';
              this.pdfDetails.reportShortName = 'Mk3 Work Instruction';
              this.pdfDetails.pdfTitle = (
                'Work Instruction Sheet ' +
                (this.pdfDetails.instructionItemId
                  ? this.pdfDetails.instructionItemId?.toString()
                  : '')
              ).trimEnd();
              this.pdfDetails.phone = '02 9839 3820';
              this.pdfDetails.email = 'trades@mk3.com.au';
              this.pdfDetails.website = 'www.mk3.com.au';
              this.pdfDetails.logoHeight = 40;
              this.pdfDetails.logoWidth = 40;
              this.pdfDetails.logoTop = 10;
              this.pdfDetails.logoImgSrc = 'assets/mk3-logo.png';
              this.pdfDetails.footerLogos = true;
              this.pdfDetails.fromEmailAddress = 'trades@mk3.com.au';
              this.form.controls.siteAttendOptions.setValidators(
                Validators.required
              );
              this.form.controls.jobCost.setValidators([
                Validators.maxLength(10),
                Validators.pattern(
                  /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
                ),
              ]);
              this.form.controls.jobCost.updateValueAndValidity();
              break;
            }
          }
          this.title = this.pdfDetails.pdfTitle + ' to ' + this.companyName;

          if (this.internalTrade && this.documentTypeQuote)
            this.form.controls['emailBody'].setValue(
              'Hello ' +
                receiverName +
                ',\r\n\r\nPlease find attached NIB ' +
                documentType +
                ' for ' +
                this.riskAddress +
                '.\r\nWe kindly request that you respond to this email with your quotation. Your prompt attention to this matter is highly appreciated' +
                '.\r\n\r\nRegards\r\n' +
                this.data.userName
            );
          else if (this.internalTrade && !this.documentTypeQuote)
            this.form.controls['emailBody'].setValue(
              'Hello ' +
                receiverName +
                ',\r\n\r\nPlease find attached NIB ' +
                documentType +
                ' for ' +
                this.riskAddress +
                '.\r\n\r\nFollowing MUST be completed:' +
                '\r\n\r\n<b>Take 5 for each claim</b>' +
                '.\r\n“sign in” when you arrive and “sign out” when leaving the property.' +
                '<b>\r\nWork Instruction sign off sheets</b>' +
                '.\r\nTradesman to sign and date with insured signature at completion of works emailed to <a href=mailto:nib@nibaustralia.com.au>nib@nibaustralia.com.au</a> and to the assessor.' +
                '<b>\r\nPhotos on OneDrive</b>' +
                '.\r\nBefore and after works completed' +
                '.\r\n\r\nRegards\r\n' +
                this.data.userName
            );
          else if (!this.internalTrade && this.documentTypeQuote)
            this.form.controls['emailBody'].setValue(
              'Hello ' +
                receiverName +
                ',\r\n\r\nPlease find attached ' +
                documentType +
                ' from ' +
                this.pdfDetails.officeShortName +
                ' for ' +
                this.riskAddress +
                '.\r\nWe kindly request that you respond to this email with your quotation. Your prompt attention to this matter is highly appreciated' +
                '.\r\n\r\nRegards\r\n' +
                this.data.userName
            );
          else if (!this.internalTrade && !this.documentTypeQuote)
            this.form.controls['emailBody'].setValue(
              'Hello ' +
                receiverName +
                ',\r\n\r\nPlease find attached ' +
                documentType +
                ' from ' +
                this.pdfDetails.officeShortName +
                ' for ' +
                this.riskAddress +
                '.\r\n\r\nPlease ensure the following:' +
                '\r\n\r\n<b>1. Provide us with the date of commencement of works and expected end date by return email ‘Reply All’ ' +
                '.\r\n2. Notify us when works are completed, making sure both signatures and date are on the work instruction' +
                '.\r\n3. Job number and assessor name is included on the invoice' +
                '.\r\n4. You submit your invoice by responding to this email (' + this.pdfDetails.email + ').</b>' +
                '\r\n\r\nPlease note, the sign off sheet must be <b><u>signed by the insured at the completion of works and submitted along with the invoice.</u></b> We cannot release payment until this is done.' +
                '\r\n\r\n<u>Restorers</u> please note:' +
                '\r\nWe require that you submit on first day of attendance' +
                '\r\n1. Photos of installed equipment to each room' +
                '\r\n2. Photos showing overall room/s where equipment is installed' +
                '\r\n3. Inventory of equipment installed and works completed on initial site visit' +
                '\r\n4. We kindly request that you send your invoice by responding to this email (' + this.pdfDetails.email + ')' +
                '\r\nPlease ensure our reference number is in the subject line of any email communications</b>.' +
                '\r\n\r\nRegards\r\n' +
                this.data.userName
            );
        },
      });
      let receiverName = this.isInternalInstructions
        ? this.internalTrade?.displayName
        : this.contractor?.mainContactName;
      let documentType =
        this.pdfDetails.instructionTypeId == 1 ||
        this.pdfDetails.instructionTypeId == 3 ||
        this.pdfDetails.instructionTypeId == 5
          ? 'instruction sheet'
          : 'quote request';
      this.form.controls.siteAttendOptions.updateValueAndValidity();
    }
  }

  getCustomerContacts() {
    if (this.case) {
      this.isLoading = false;
      var objCustomerContact = new CustomerContact();
      objCustomerContact.id = -1;
      objCustomerContact.description = '--- Select ---';
      this.customerContactsArray.push(objCustomerContact);

      //Get Claim Contacts
      this.restService.getClaimContacts(this.case.refNo).subscribe(
        (contacts) => {
          this.customerContactsArray = contacts.map((contact: ClaimContact) => {
            return {
              id: contact.contactID,
              number: contact.phone,
              name: contact.name,
              description: contact.note,
              claimContactRole: contact.claimContactRole,
            };
          });
          var objCustomerContact = new CustomerContact();
          objCustomerContact.id = -1;
          objCustomerContact.description = '--- Select ---';
          this.customerContactsArray.push(objCustomerContact);
          var objOtherContact = new CustomerContact();
          objOtherContact.id = 0;
          objOtherContact.description = 'Other';
          this.customerContactsArray.push(objOtherContact);

          this.customerContactsArray.sort((a, b) => (a.id > b.id ? 1 : -1));
        },
        (error) => {
          this.isLoading = false;
          this.alertService.error(error);
        }
      );
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  radioChange(event: MatRadioChange) {
    this.siteOptionSelected = event.value;
    this.form.controls.siteAttendOptions.setValue(this.siteOptionSelected);
    this.form.controls.siteAttendOptions.updateValueAndValidity();
    if (event.value == 2) {
      if (
        this.form.controls.instructionTypeId.value == 1 ||
        this.form.controls.instructionTypeId.value == 3
      ) {
        this.form.controls.siteAttendOptions.setValidators(Validators.required);
        return this.getCustomerContacts();
      } else {
        this.form.controls.siteAttendOptions.setValidators(null);
        return;
      }
    } else return;
  }

  onChangeInspectionTime(event: any) {
    const time = event;
    if (time != '') {
      this.inspectionTime = this.functionsService.timeStringToTime(
        time.toString()
      );
    }
  }

  onChangeCustomerContactType(event?: any) {
    if (event) {
      const id = Number(event?.target.value);
      this.customerContactType = id;
    }
  }

  getTitle(instructionTypeId: number) {
    var retVal = '';
    instructionTypeId = 3;
    switch (instructionTypeId) {
      case 1:
      case 3:
      case 5:
        retVal = 'Work Instruction Sheet';
        break;
      case 2:
      case 4:
        retVal = 'Quote Request';
    }
    return retVal;
  }

  /*getHeadOfficeEmail(instructionTypeId: number) {
    var retVal = '';
    switch (instructionTypeId) {
      case 1:
      case 3:
        retVal = 'nib@nibaustralia.com.au';
        break;
      case 2:
      case 4:
        retVal = 'info@revivalrestorations.com.au';
        break;
      case 5:
        retVal = 'mk3@mk3.com.au';
        break;
    }
    return retVal;
  }*/

  public async onSubmit() {
    this.isLoading = true;
    this.generatePDF(false);
  }

  public async onPreview(isSendEmail: boolean) {
    this.isLoading = true;
    this.generatePDF(isSendEmail);
  }

  getSiteAttendInfo() {
    let retVal: string = '';
    if (!this.documentTypeQuote) {
      switch (this.siteOptionSelected) {
        case 1:
          let inspTimeString: string = '';
          if (this.inspectionTime.toString() != '[object Object]')
            inspTimeString = this.inspectionTime.toString();
          retVal =
            this.datePipe
              .transform(this.inspectionDate, 'dd/MM/yyyy')
              ?.toString() +
            ' at ' +
            this.functionsService.timeConvert24to12(inspTimeString);
          break;
        case 2:
          let arrayIndex: number = this.customerContactsArray.findIndex(
            (x) => x.id === this.customerContactType
          );
          retVal = (
            'Organise directly with ' +
            this.customerContactsArray[arrayIndex].name +
            ' on ' +
            this.customerContactsArray[arrayIndex].number +
            ' and advise in response to this email. ' +
            (this.customerContactsArray[arrayIndex].description
              ? this.customerContactsArray[arrayIndex].description
              : '')
          ).trim();
          break;
        case 3:
          retVal = this.form.controls.customSiteAttendInstructions.value;
          break;
      }
    }
    return retVal;
  }

  generatePDF(isSendEmail: boolean) {
    this.inspectionDate = this.datePipe
      .transform(this.form.controls.inspectionDate.value, 'yyyy-MM-dd')
      ?.toString()!;

    try {
      let printElement: any = document.getElementById('print');
      html2canvas(printElement).then(() => {
        let pdf = new jsPDF('p', 'mm', 'a4');
        var pdfWidthInMM = 210; // width of A4 in mm
        const pdfHeight: number = pdf.internal.pageSize.height;
        let normalFont: Font = pdf.getFont();
        var pageWidth = pdf.internal.pageSize.getWidth();
        var leftMargin = 10;
        var rightMargin = 10;
        var lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor;
        var top = 60;

        var imageHeight = 40;
        var imageWidth = 40;
        var imageTop = 10;
        var img = new Image();

        pdf.setFontSize(10);
        pdf.text(
          this.datePipe.transform(new Date(), 'dd MMMM yyyy')?.toString()!,
          pageWidth - 40,
          50
        );

        autoTable(pdf, {
          startY: top,
          margin: { horizontal: leftMargin, vertical: 10 },
          theme: 'plain',
          styles: { cellPadding: 0.5 },
          columnStyles: {
            col1: {
              halign: 'left',
              fontStyle: 'bold',
              cellWidth: (pdf.internal.pageSize.getWidth() - 20) * 0.12,
            },
            col2: {
              halign: 'left',
              cellWidth: (pdf.internal.pageSize.getWidth() - 20) * 0.38,
            },
            col3: {
              halign: 'left',
              fontStyle: 'bold',
              cellWidth: (pdf.internal.pageSize.getWidth() - 20) * 0.12,
            },
            col4: {
              halign: 'left',
              cellWidth: (pdf.internal.pageSize.getWidth() - 20) * 0.38,
            },
          },
          body: [
            {
              col1: { content: 'Head Office Contact Details', colSpan: 2 },
              col3: { content: 'Contractor Details', colSpan: 2 },
            },
            {
              col1: 'Head Office: ',
              col2: this.pdfDetails.phone,
              col3: { content: this.companyName, colSpan: 2 },
            },
            {
              col1: 'Assessor: ',
              col2:
                this.functionsService.blankIfNull(this.case.consultant) +
                ' ' +
                this.functionsService.blankIfNull(this.consultantPhone),
              col3: 'Email: ',
              col4: this.companyEmail,
            },
            {
              col1: 'Email: ',
              col2: this.functionsService.blankIfNull(this.pdfDetails.email),
              col3: 'Phone: ',
              col4: this.companyPhone,
            },
          ],
          columns: [
            { header: '', dataKey: 'col1' },
            { header: '', dataKey: 'col2' },
            { header: '', dataKey: 'col3' },
            { header: '', dataKey: 'col4' },
          ],

          didParseCell: function (data) {
            if (
              ((data.column.index === 1 && data.row.index === 3) ||
                (data.column.index === 3 && data.row.index === 2)) &&
              data.cell.section === 'body'
            ) {
              data.cell.styles.textColor = [0, 0, 255];
            }
          },
        });

        let finalY = (pdf as any).lastAutoTable.finalY + 10;
        pdf.setFont(normalFont.fontName, 'bold');
        pdf.setFontSize(15);

        if (this.isInternalInstructions)
          pdf.text(
            this.pdfDetails.pdfTitle + ' to ' + this.companyName,
            pageWidth / 2,
            finalY,
            { align: 'center' }
          );
        else
          pdf.text(this.pdfDetails.pdfTitle, pageWidth / 2, finalY, {
            align: 'center',
          });

        pdf.setFontSize(11);
        pdf.setFont(normalFont.fontName, 'normal');
        top = (pdf as any).lastAutoTable.finalY + 18;

        pdf.setFont(normalFont.fontName, 'bold');
        pdf.text('Job Number: ' + this.case.refNo, pageWidth / 2, top, {
          align: 'center',
        });
        top += 10;

        pdf.text('Customer Details', leftMargin, top);
        pdf.setFont(normalFont.fontName, 'normal');

        top = (pdf as any).lastAutoTable.finalY + 30;
        autoTable(pdf, {
          startY: top,
          margin: { horizontal: leftMargin, vertical: 10 },
          theme: 'plain',
          styles: { cellPadding: 0.5 },
          columnStyles: {
            col1: {
              halign: 'left',
              fontStyle: 'bold',
              cellWidth: (pdf.internal.pageSize.getWidth() - 20) * 0.21,
            },
            col2: {
              halign: 'left',
              cellWidth: (pdf.internal.pageSize.getWidth() - 20) * 0.79,
            },
          },
          body: [
            {
              col1: 'Surname: ',
              col2: this.functionsService.blankIfNull(this.case.lastNameS),
            },
            {
              col1: 'Contact: ',
              col2: this.getContactDetails(this.customerContactType),
            },
            {
              col1: 'Site Address: ',
              col2:
                this.functionsService.blankIfNull(this.case.situationStreet) +
                ' ' +
                this.functionsService.blankIfNull(this.case.situationSuburb) +
                ' ' +
                this.functionsService.blankIfNull(this.case.state) +
                ' ' +
                this.functionsService.blankIfNull(this.case.postCode),
            },
            {
              col1: this.getSiteAttendInfo() != '' ? 'Attend Site: ' : '',
              col2: this.getSiteAttendInfo(),
            },
          ],
          columns: [
            { header: '', dataKey: 'col1' },
            { header: '', dataKey: 'col2' },
          ],
        });

        top = (pdf as any).lastAutoTable.finalY + 10;
        pdf.setFont(normalFont.fontName, 'bold');

        if (!this.documentTypeQuote) {
          pdf.text('Important Information', leftMargin, top);

          pdf.setFont(normalFont.fontName, 'normal');
          top += 10;

          if (this.isInternalInstructions) {
            pdf.text(
              '• Please ensure your risk assessment is carried out as per your training. ',
              leftMargin,
              top
            );
            top += 5;

            pdf.text(
              '• Please ensure all associated damaged material and rubbish are removed from site and the site is left',
              leftMargin,
              top
            );
            top += 5;

            pdf.text('  appropriately clean and tidy.', leftMargin, top);
            top += 10;

            pdf.setFont(normalFont.fontName, 'bold');
            pdf.text(
              'The following must be ticked before departing site:',
              leftMargin,
              top
            );
            pdf.setFont(normalFont.fontName, 'normal');
            top += 10;
            pdf.text(
              '[  ] Ensure that a Take 5 is completed on arrival and departure',
              leftMargin,
              top
            );
            top += 5;

            pdf.text(
              '[  ] Please take photos of completed repairs and upload to OneDrive',
              leftMargin,
              top
            );
            top += 5;
          } else {
            var paragraph =
              'This Work Instruction Sheet has been issued to the nominated contractor detailed above. Should the nominated contractor intend to utilise sub-contractors to complete the Scope of Works detailed on the following page/s the nominated contractor must immediately notify National Insurance Builders and seek their express approval. The information within the Work Instruction Sheet is confidential and to be used only to complete the Scope of Works on the following page/s and is not to be distributed.\n\nBy acceptance of this work order the nominated contractor is acknowledging that they currently hold the appropriate trade licence and insurances to complete the detailed scope of work, within the relevant State or Territory to which the work will be completed.\n\nAdditionally, by accepting this work instruction, the nominated contractor agrees that they or any of their subcontractors will not undertake other works or services at the site address, that are not specified on this instruction sheet. The contractor further agrees that they shall not use any confidential information or propriety information of ' +
              this.pdfDetails.officeFullName +
              ' or any of its customers for the benefit of any other party or for their own personal gain.\n\nPlease ensure your risk assessment is carried out as per your training.\n\nPlease ensure all associated damaged material and rubbish are removed from site and the site is left appropriately clean and tidy.';
            var splittexText = pdf.splitTextToSize(
              paragraph,
              pdfWidthInMM - leftMargin - rightMargin,
              { align: 'justify' }
            );

            pdf.text(splittexText, leftMargin, top, splittexText, {
              align: 'justify',
            });
            pdf.setFont(normalFont.fontName, 'bold');

            var lines = splittexText.length; // splitted text is a string array
            var blockHeight = lines * lineHeight;
            top += blockHeight;

            top -= 25;
            pdf.setFont(normalFont.fontName, 'bold');
            pdf.text(
              'This is not a purchase order but a works authorisation only.',
              pageWidth / 2,
              top,
              { align: 'center' }
            );
            pdf.setFont(normalFont.fontName, 'normal');
            top += 10;
          }

          pdf.addPage();
          top = 60;

          pdf.setFont(normalFont.fontName, 'bold');
          pdf.text(
            'Consultant: ' +
              this.functionsService.blankIfNull(this.case.consultant),
            leftMargin,
            top
          );

          // Restorer Instruction notes
          if (this.pdfDetails.instructionTypeId == 3) {
            top += 10;
            pdf.text('Restorers please note:', leftMargin, top);
            pdf.setFont(normalFont.fontName, 'normal');

            top = this.setPdfElementTop(pdf, top, 1);
            pdf.text(
              'We require that you submit on first day of attendance',
              leftMargin,
              top
            );

            top = this.setPdfElementTop(pdf, top, 1);
            pdf.text(
              '1. Photos of installed equipment to each room',
              leftMargin,
              top
            );

            top = this.setPdfElementTop(pdf, top, 1);
            pdf.text(
              '2. Photos showing overall room/s where equipment is installed',
              leftMargin,
              top
            );

            top = this.setPdfElementTop(pdf, top, 1);
            pdf.text(
              '3. Inventory of equipment installed; moisture readings and works completed on initial site visit',
              leftMargin,
              top
            );

            top = this.setPdfElementTop(pdf, top, 1);
            pdf.text(
              '4. We kindly request that you send your invoice by responding to this email (' + this.pdfDetails.email + ')',
              leftMargin,
              top
            );

            top = this.setPdfElementTop(pdf, top, 1);
            pdf.text(
              'Please ensure our reference number is in the subject line of any email communications',
              leftMargin,
              top
            );
            top += 10;
          }

          pdf.setFont(normalFont.fontName, 'normal');
          top += 5;

          pdf.setFont(normalFont.fontName, 'bold');
          pdf.text(
            'Scope of Works at ' +
              this.functionsService.blankIfNull(this.case.situationStreet) +
              ' ' +
              this.functionsService.blankIfNull(this.case.situationSuburb) +
              ' ' +
              this.functionsService.blankIfNull(this.case.state) +
              ' ' +
              this.functionsService.blankIfNull(this.case.postCode),
            leftMargin,
            top
          );
          pdf.setFont(normalFont.fontName, 'normal');
          top += 5;
        } else {
          pdf.setFont(normalFont.fontName, 'bold');
          pdf.text(
            'Please provide a quotation on the following work:',
            leftMargin,
            top
          );
          pdf.setFont(normalFont.fontName, 'normal');
          top += 5;
        }

        var splittexText = pdf.splitTextToSize(
          this.form.controls.description.value
            ? this.form.controls.description.value
            : '',
          pdfWidthInMM - leftMargin - rightMargin,
          { align: 'justify' }
        );

        var lines = splittexText.length;
        splittexText.forEach((element: string | string[]) => {
          top = this.setPdfElementTop(pdf, top);
          pdf.text(element, leftMargin, top, splittexText, {
            align: 'justify',
          });
        });

        top = this.setPdfElementTop(pdf, top, 2);
        pdf.setFont(normalFont.fontName, 'bold');

        if (!this.documentTypeQuote) {
          pdf.addPage();
          top = 60;

          top = this.setPdfElementTop(pdf, top, 3);

          pdf.setFont(normalFont.fontName, 'bold');
          pdf.text('Sign off:', leftMargin, top);
          top = this.setPdfElementTop(pdf, top, 2);

          pdf.text(
            'Contractor’s Sign off – ' + this.companyName,
            leftMargin,
            top
          );
          pdf.setFont(normalFont.fontName, 'normal');

          top = this.setPdfElementTop(pdf, top, 2);
          pdf.text(
            'I have completed all work at ' +
              this.riskAddress +
              ' as detailed within the Scope of Works.',
            leftMargin,
            top
          );
          top = this.setPdfElementTop(pdf, top, 3);
          pdf.text(
            'Contractor’s Signature: _______________________________________ Date: ________________________',
            leftMargin,
            top
          );

          top = this.setPdfElementTop(pdf, top, 2);
          pdf.setFont(normalFont.fontName, 'bold');
          pdf.text('Customer’s Sign off', leftMargin, top);
          pdf.setFont(normalFont.fontName, 'normal');

          top = this.setPdfElementTop(pdf, top, 2);
          pdf.text(
            'Customer’s Name: _______________________________________________________________________',
            leftMargin,
            top
          );
          top = this.setPdfElementTop(pdf, top, 3);
          pdf.text(
            'Customer’s Signature: _______________________________________ Date: ________________________',
            leftMargin,
            top
          );

          if (this.isInternalInstructions) {
            top = this.setPdfElementTop(pdf, top, 5);
            pdf.text(
              'Hours on Site: _________________________ Material Invoice Totals: ______________________________',
              leftMargin,
              top
            );
            top = this.setPdfElementTop(pdf, top, 3);
            pdf.text(
              'Pickup/ Delivery Hrs: ____________________ Rubbish Disposal Hrs: _______________________________',
              leftMargin,
              top
            );
          }
        } else {
          pdf.text(
            'Please contact ' +
              this.case.consultant +
              ' on Ph. ' +
              this.consultantPhone +
              ' with any queries',
            leftMargin,
            top
          );
          pdf.setFont(normalFont.fontName, 'normal');
          top = this.setPdfElementTop(pdf, top, 2);
          paragraph =
            'The above is to include for ensuring safety of the work area, removal of all associated waste materials and cleaning up upon completion of the work.';
          var splittexText = pdf.splitTextToSize(
            paragraph,
            pdfWidthInMM - leftMargin - rightMargin,
            { align: 'justify' }
          );
          pdf.text(splittexText, leftMargin, top, splittexText, {
            align: 'justify',
          });
        }

        var pageCount = pdf.getNumberOfPages(); //Total Pages

        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          img.src = this.pdfDetails.logoImgSrc;
          pdf.addImage(
            img,
            'png',
            pageWidth / 2 - this.pdfDetails.logoWidth / 2,
            this.pdfDetails.logoTop,
            this.pdfDetails.logoWidth,
            this.pdfDetails.logoHeight
          );

          if (this.pdfDetails.footerLogos) {
            //Page Footer - Logos
            imageHeight = 20;
            imageWidth = 20;
            var imgIso9001 = new Image();
            imgIso9001.src = 'assets/iso9001-logo.png';
            pdf.addImage(
              imgIso9001,
              'png',
              imageTop,
              pdfHeight - 30,
              imageWidth,
              imageHeight
            );

            var imgMba = new Image();
            imgMba.src = 'assets/mba-logo.png';
            pdf.addImage(
              imgMba,
              'png',
              pdf.internal.pageSize.width - 30,
              pdfHeight - 30,
              imageWidth,
              imageHeight
            );
          }

          //Page Footer - Company Info
          pdf.setFontSize(8);
          if (this.pdfDetails.officeShortName == 'NIB') {
            pdf.text(
              'NSW Lic. 2370130C | QLD Lic. 1205587 | SA Lic. BLD241081 | ACT Lic. 20111075 | WA Lic. S1325 | VIC Lic. BD-U 45123',
              pageWidth / 2,
              pdfHeight - 33,
              { align: 'center' }
            );
          } else if (this.pdfDetails.officeShortName == 'RR') {
            pdf.text('* Storm * Flood * Fire', pageWidth / 2, pdfHeight - 33, {
              align: 'center',
            });
          }
          pdf.text(
            '______________________________________________________________________________________________________________________________________',
            0,
            pdfHeight - 32,
            { align: 'justify' }
          );
          pdf.setFontSize(9);
          pdf.text(
            this.pdfDetails.officeFullName + ' Pty. Ltd',
            pageWidth / 2,
            pdfHeight - 25,
            { align: 'center' }
          );
          pdf.text(
            'PO Box 844 Baulkham Hills NSW 1755',
            pageWidth / 2,
            pdfHeight - 21,
            { align: 'center' }
          );
          pdf.text(
            'Telephone ' + this.pdfDetails.phone,
            pageWidth / 2,
            pdfHeight - 17,
            { align: 'center' }
          );

          if (this.pdfDetails.officeShortName == 'NIB') {
            pdf.setTextColor('blue');
            pdf.textWithLink(
              'www.nibaustralia.com.au',
              pageWidth / 2,
              pdfHeight - 13,
              { url: 'https://www.nibaustralia.com.au', align: 'center' }
            );
            pdf.text('____________________', pageWidth / 2, pdfHeight - 13, {
              align: 'center',
            });
            pdf.setTextColor('black');
            pdf.text('ABN 95 149 914 625', pageWidth / 2, pdfHeight - 9, {
              align: 'center',
            });
          }
        }

        for (let i = 1; i < pageCount; i++) {
          pdf.setPage(i + 1);
          let pageCurrent = pdf.getCurrentPageInfo().pageNumber; //Current Page
          pdf.setFontSize(10);
          //Job Number
          pdf.text(
            'Ref: ' + this.case.refNo + ' ' + this.case.lastNameS,
            pageWidth - 12,
            8,
            { align: 'right' }
          );
          pdf.setFontSize(8);
          //Page Number
          pdf.text(
            'Page: ' + pageCurrent + '/' + pageCount,
            pageWidth - 12,
            pdfHeight - 5,
            { align: 'center' }
          );
        }

        var imgWatermark = new Image();
        imgWatermark.src = 'assets/draft-watermark.png';
        const watermarkDimensions = 100;

        if (!isSendEmail) {
          for (let i = 0; i < pageCount; i++) {
            pdf.setPage(i + 1);
            pdf.addImage(
              imgWatermark,
              'png',
              pageWidth / 2 - watermarkDimensions / 2,
              pdf.internal.pageSize.getHeight() / 2 - watermarkDimensions / 2,
              watermarkDimensions,
              watermarkDimensions
            );
          }
        }

        let fileName = '';
        if (this.isInternalInstructions)
          fileName =
            this.case.refNo +
            ' ' +
            this.case.lastNameS +
            ' - NIB Work Instruction ' +
            (this.pdfDetails.instructionItemId
              ? this.pdfDetails.instructionItemId?.toString()
              : ''
            ).trimEnd() +
            ' - ' +
            this.internalTrade.displayName;
        else
          fileName =
            this.case.refNo +
            ' ' +
            this.case.lastNameS +
            ' - ' +
            this.pdfDetails.reportShortName +
            (
              ' ' +
              (this.pdfDetails.instructionItemId
                ? this.pdfDetails.instructionItemId?.toString()
                : '')
            ).trimEnd() +
            ' - ' +
            this.contractor.tradingName;

        pdf.setProperties({ title: fileName + ' - ' + this.case.refNo });
        var blobPDF = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        });
        var blobURL = URL.createObjectURL(blobPDF);

        if (isSendEmail) {
          if ((fileName.indexOf(' Work Instruction - ')>0 && (this.form.controls.instructionTypeId.value === 2 || this.form.controls.instructionTypeId.value === 4))
            || (fileName.indexOf(' Quote Request - ')>0 && (this.form.controls.instructionTypeId.value === 1 || this.form.controls.instructionTypeId.value=== 3 || this.form.controls.instructionTypeId.value === 5))){
              Swal.fire({
                title:'<strong>PDF Sheet Type Mismatch Instruction Type!</strong><br/> The PDF sheet type not match the selected Instruction type, to make sure send the correct Instruction, please resend again!',
                icon:'error', 
                confirmButtonText: 'OK' }).then((result) => { 
                  if (result.isConfirmed) { 
                    this.dialogRef.close();;
                  } 
              }); 
            } 
        else {
          Swal.fire({
            title:
              'Please make sure you have checked the preview of the document generated and confirm all the information is correct.<br/>If you select Yes, the email will be sent immedietly and it cannot be reversed.<br/><br/>Are you sure you want to email the document generated, to the Contractor/ Internal Trade you have selected?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: `Yes`,
            denyButtonText: `No`,
            confirmButtonColor: '#007bff',
            denyButtonColor: '#dc3545',
            focusDeny: true,
            width: 450,
            padding: '1em',
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              var pdfData = pdf.output('datauristring').split(',')[1];
              let from = this.pdfDetails.fromEmailAddress;
              let to = this.contractor
                        ? this.contractor.mainContactEmail
                        : this.internalTrade.mail;
           
               let cc = this.claimOwnerEmails +';' + this.getAdditionalTradesEmailList();
   
               let bcc = 'test@mk3.com.au; mk3@mk3.com.au';
              let subject = fileName;
              let body = this.form.controls['emailBody'].value?.replace(
                /(?:\r\n|\r|\n)/g,
                '<br>'
              ).trim();
              let attachmentName = fileName + '.pdf';

              var email: Email;
              email = {
                from: from,
                to: to,
                cc: cc,
                bcc: bcc,
                subject: subject,
                body: body,
                attachmentName: attachmentName,
                attachmentContent: pdfData,
                extraAttachmentName: this.selectedFiles.map(x=>x.name),
                extraAttachmentContent: this.selectedFiles.map(x=>x.base64
                ? x.base64
                  ?.toString()
                  .replace('data:', '')
                  .replace(/^.+,/, '')
                : ''),
              };
     
              this.restService.sendEmail(email, ('Email Type:Work Instructions or Quote Request'+', User Name:'+ this.msalService.instance.getActiveAccount()!.name! 
                                  + ', RefNo:' + this.case.refNo.toString() 
                                  + ', ContractorId:'+ (this.data.internalTrade? this.internalTrade.employeeId.toString():this.contractor.contractorId.toString())
                                  + ', Contractor:'+ (this.data.internalTrade? this.internalTrade.displayName:this.contractor.tradingName))).subscribe(
                (response: any) => {
                  if (response.status == 200) {
                    let additionalTradesString = '';
                    this.additionalInternalTradesArray.forEach(
                      (internalTrade: InternalTrade) => {
                        additionalTradesString +=
                          internalTrade.employeeId + ',';
                      }
                    );

                    this.emailRecord = {
                      id: 0,
                      contractorId: !this.isInternalInstructions
                        ? this.contractor.contractorId
                        : null,
                      internalTradeId: this.isInternalInstructions
                        ? this.internalTrade.employeeId
                        : null,
                      instructionTypeId:
                        this.form.controls.instructionTypeId.value,
                      instructionItemId: this.pdfDetails.instructionItemId,
                      refNo: this.case.refNo,
                      pdfData: pdfData,
                      attachmentName: attachmentName,
                      fromEmail: from,
                      toEmail: to,
                      ccEmails: cc,
                      subject: subject,
                      body: this.form.controls['emailBody'].value?.replace(
                        /(<([^>]+)>)/gi,
                        ''
                      ),
                      sentBy:
                        this.msalService.instance.getActiveAccount()!.name!,
                      dateSent: null,
                      signedReturned: null,
                      jobCost:
                        this.documentTypeQuote ||
                        this.form.controls['jobCost'].value == ''
                          ? null
                          : Number(this.form.controls['jobCost'].value),
                      retracted: null,
                      retractedBy: null,
                      retractedOn: null,
                      startDate: null,
                      endDate: null,
                      statusId: (this.instructionTypeId == "2" || this.instructionTypeId == "4") ? 16 : 1,
                      additionalInternalTrades: additionalTradesString,
                      jtmRating: null,
                      qoWRating: null,
                      hseRating: null,
                      qualityComments: null,
                    };

                    let datumNoteForInternalTrades = '';

                    if (this.isInternalInstructions) {
                      this.additionalInternalTradesArray.forEach(
                        (internalTrade: InternalTrade) => {
                          datumNoteForInternalTrades +=
                            internalTrade.displayName +
                            ' (ID ' +
                            internalTrade.employeeId +
                            ')' +
                            ', ';
                        }
                      );

                      if (datumNoteForInternalTrades.length >= 2)
                        datumNoteForInternalTrades =
                          this.title +
                          ' (ID ' +
                          this.internalTrade.employeeId +
                          ')' +
                          ', ' +
                          datumNoteForInternalTrades.substring(
                            0,
                            datumNoteForInternalTrades.length - 2
                          );
                      else
                        datumNoteForInternalTrades =
                          this.title +
                          ' (ID ' +
                          this.internalTrade.employeeId +
                          ')';
                    }

                    let notes = '';
                    if (this.form.controls['description'].value.length < 2200)
                      notes =
                        (!this.isInternalInstructions
                          ? this.title +
                            ' (ID ' +
                            this.contractor.contractorId +
                            ')'
                          : datumNoteForInternalTrades) +
                        '\r\n' +
                        this.form.controls['description'].value;
                    else
                      notes =
                        (!this.isInternalInstructions
                          ? this.title +
                            ' (ID ' +
                            this.contractor.contractorId +
                            ')'
                          : datumNoteForInternalTrades) +
                        '\r\n' +
                        'Please refer to the Contractors App for more details as the details exceeds the length of text that can be displayed here';

                    this.isLoading = true;
                    this.restService
                      .addNewEmailRecord(this.emailRecord)
                      .pipe(first())
                      .subscribe({
                        next: (x) => {
                          this.emailRecord.id = x;

                          let newNote: DatumNote = {
                            refNo: this.case.refNo,
                            date: this.datePipe
                              .transform(new Date(), 'yyyy-MM-dd')
                              ?.toString()!,
                            addedBy:
                              this.msalService.instance.getActiveAccount()!
                                .name!,
                            initiatedBy: 'Mk3 Staff',
                            noteTypeId: 4,
                            subTypeId: 2,
                            reasonId: this.documentTypeQuote ? 33 : 14,
                            notes: notes,
                            private: -1,
                            timeStamp: null,
                            followUpDate:
                              this.functionsService.getDayAfterBusinessDays(2),
                            followUpUser: null,
                            followUpComplete: null,
                            clientId: 1,
                          };

                          this.restService
                            .addNewNoteToDatum(newNote)
                            .pipe(first())
                            .subscribe({
                              next: () => {
                                if (!this.documentTypeQuote) {
                                  try {
                                    this.updateLastUsedDate();
                                  } catch {}
                                }

                                try {
                                  let jobHistory = new JobHistory();
                                  jobHistory.jobId = this.emailRecord.id;
                                  jobHistory.statusId = (this.instructionTypeId == "2" || this.instructionTypeId == "4") ? 16 : 1;
                                  jobHistory.comments = ((this.instructionTypeId == "2" || this.instructionTypeId == "4") ? 'Quote Request Sent' : 'Instructions Sent') + (this.isInternalInstructions? '. Attend Site Date Time:' + this.inspectionDate + ' ' + (this.inspectionTime.toString()!= '[object Object]'? this.inspectionTime.toString():'12:00 AM'):'');
                                  jobHistory.date = null;//new Date();
                                  jobHistory.addedBy =
                                    this.msalService.instance.getActiveAccount()!.name!;
                                  jobHistory.timeStamp = null;
                                  this.restService
                                    .addNewJobHistoryRecord(jobHistory)
                                    .subscribe((x) => {
                                      console.log(x);
                                    });
                                } catch {}

                                this.dialog.closeAll();
                                Swal.fire(
                                  'Success!',
                                  'Instructions/ Quote email sent successfully and the details has been recorded!',
                                  'success'
                                ).then(() => {
                                  pdf.save(fileName);
                                  window.open(blobURL, '_blank');

                                  /*if (this.emailRecord.contractorId) {
                                    this.router
                                      .navigate(
                                        [
                                          '../contractor-details/' +
                                            this.emailRecord.contractorId,
                                        ],
                                        { queryParams: { tabId: 4 } }
                                      )
                                      .then(() => {
                                        window.location.reload();
                                      });
                                  } else
                                    this.router
                                      .navigate([
                                        '../internal-trade-details/' +
                                          this.emailRecord.internalTradeId,
                                      ])
                                      .then(() => {
                                        window.location.reload();
                                      });*/

                                      this.router
                                      .navigate([
                                        '../job-details/' +
                                          this.emailRecord.id,
                                      ])
                                      .then(() => {
                                        window.location.reload();
                                      });
                                });
                              },
                              error: (error: string) => {
                                this.alertService.error(error);
                              },
                            });
                        },
                        error: (error: string) => {
                          this.isLoading = false;
                          this.alertService.error(error);
                        },
                      });
                  } else {
                    this.alertService.error(
                      'Error: Status code = ' + response.status
                    );
                    this.isLoading = false;
                  }
                },
                // (error: any) => {
                //   console.log(error);
                // }
                (err: any) => {
                  Swal.fire(
                    'Error (Generate PDF): restService.sendEmail: ' + err,
                    '',
                    'error'
                  );
                  console.log(
                    'Error (Generate PDF): restService.sendEmail: ' + err
                  );
                },
              );
              if(this.contractor.isCompliant=="No")
              {
                this.restService.sendEmailToStateManager(this.contractor,this.data.userName,this.data.userEmail, false, 'Email Type:Work Instructions or Quote Request - Send Email To Manager'+', User Name:'+ this.data.userName 
                + ', RefNo:' + this.case.refNo.toString() 
                + ', ContractorId:'+ (this.data.internalTrade? this.internalTrade.employeeId.toString():this.contractor.contractorId.toString())
                + ', Contractor:'+ (this.data.internalTrade? this.internalTrade.displayName:this.contractor.tradingName)).subscribe();//////
              }
              if((this.form.controls.instructionTypeId.value === 1 || this.form.controls.instructionTypeId.value=== 3 || this.form.controls.instructionTypeId.value === 5) && this.contractor.tncStatus.substring(0,6).toLowerCase()!=='signed'){
                this.restService.sendEmailToStateManager(this.contractor,this.data.userName,this.data.userEmail, true, 'Email Type:Work Instructions - Send To Not Sign TnC Agreement Contractor Email To Manager'+', User Name:'+ this.data.userName 
                  + ', RefNo:' + this.case.refNo.toString() 
                  + ', ContractorId:'+ (this.data.internalTrade? this.internalTrade.employeeId.toString():this.contractor.contractorId.toString())
                  + ', Contractor:'+ (this.data.internalTrade? this.internalTrade.displayName:this.contractor.tradingName)).subscribe();
              }
             } else if (result.isDenied) {
              this.isLoading = false;
            }
          });
        }
      } else {
        pdf.save(fileName);
        window.open(blobURL, '_blank');
        this.isLoading = false;
      }
    });
  } catch (error: any) {
    this.isLoading = false;
    this.alertService.error(error);
  }
}

  getContactDetails(contactTypeId: number): string {
    if(this.customerContactType==0 )
      {
        return this.otherContact;
      }
    else if (this.customerContactType) {
      var contactDetail = this.customerContactsArray.find(
        (item) => item.id === contactTypeId
      );
      return contactDetail?.number || '';
    } else return '';
  }

  setPdfElementTop(
    pdf: jsPDF,
    top: number,
    additionalBlankLines?: number
  ): number {
    if (additionalBlankLines) top += 5 * additionalBlankLines;
    else top += 5;

    if (top > pdf.internal.pageSize.height - 45 || top < 60) {
      pdf.addPage();
      top = 60;
    }
    return top;
  }

  public onCancel = () => {};

  onAddAdditionalTrades() {
    this.isLoading = true;
    return this.restService
      .getInternalTrades()
      .subscribe((internalTradesList: Array<InternalTrade>) => {
        if (internalTradesList.length == 0) {
          Swal.fire(
            'Not Found!',
            'There are no Internal Trades Found.<br/><br/>Please contact <b><a href=https://mk3.freshservice.com/support/home target="_blank">IT Helpdesk</a></b>.',
            'error'
          );
          return true;
        } else {
          let internalTradesIds: number[] = [];
          internalTradesList.forEach((internalTrade: InternalTrade) => {
            internalTradesIds.push(internalTrade.employeeId);
          });

          this.internalTradesSelectionDialogRef = this.dialog.open(
            SelectInternalTradesComponent,
            {
              minWidth: '800px',
              maxWidth: '2000px',
              maxHeight: '680px',
              closeOnNavigation: true,
              data: {
                allTrades: internalTradesList,
                selectedTrades: this.additionalInternalTradesArray,
              },
            }
          );

          this.internalTradesSelectionDialogRef
            .afterClosed()
            .subscribe((result) => {
              this.isLoading = false;
              let emailGreetingTemp: string =
                'Hello ' +
                (this.isInternalInstructions
                  ? this.internalTrade?.displayName
                  : this.contractor?.mainContactName) +
                ', ';
              this.additionalInternalTradesArray.splice(0);
              result.forEach((element: InternalTrade) => {
                emailGreetingTemp =
                  emailGreetingTemp + element.displayName + ', ';
                this.additionalInternalTradesArray.push(element);
              });
              this.form.patchValue({
                emailBody: this.form.controls['emailBody'].value?.replace(
                  this.internalTradeEmailGreeting,
                  emailGreetingTemp
                ),
              });
              this.internalTradeEmailGreeting = emailGreetingTemp;
              this.internalTradesSelectionDialogRef.close();
            });
          return true;
        }
      });
  }

  onSelectSoW() {
    this.isLoading = true;
    return this.restService
      .getWorkItemsByRefNo(this.case.refNo)
      .subscribe((workItemsList: any) => {
        if (workItemsList.length == 0) {
          Swal.fire(
            'Not Found!',
            'There are no Scope of Works available for the Claim.<br/><br/>Please use the <b><a href=https://sow.mk3apps.com.au/ target="_blank">Scope of Works Application</a></b> to create SoWs or add manually below.',
            'info'
          ).then(() =>{
            this.isLoading = false;
          });
          return true;
        } else {
          let workItemIds: number[] = [];
          workItemsList.forEach((workItem: WorkItem) => {
            workItemIds.push(workItem.itemId);
          });

          this.sowSelectionDialogRef = this.dialog.open(
            SowSelectionDialogComponent,
            {
              minWidth: '800px',
              maxWidth: '2000px',
              maxHeight: '680px',
              closeOnNavigation: true,
              data: workItemsList,
            }
          );

          this.sowSelectionDialogRef.afterClosed().subscribe((result) => {
            this.isLoading = true;
            let desc: string = '';
            if(result && result.length>0)
              {
            result.forEach((element: string) => {
              desc = desc + element + '\r\n';
            });
            this.form.patchValue({ description: desc });
            this.form.patchValue({ scopeWorksContent: desc });
            //this.scopeWorksContent=desc;
            
            this.dialogScopeWorksRef = this.dialogScopeWorks.open(this.scopeWorks, {
              //data: { scopeWorksContent: desc }
            });   
            this.dialogScopeWorksRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
              this.cdr.detectChanges();
            });

          }
            this.sowSelectionDialogRef.close();
            this.isLoading = false;
          });
          this.cdr.detectChanges();
          return true;
        }
      });
  }

  getClaimOwnerDetils(refNo: number) {
    return this.restService
      .getClaimOwnerDetails(refNo)
      .subscribe((result): any => {
        this.claimOwnerEmails =
          (result.consultantEmail ? result.consultantEmail + ';' : '') +
          (result.coordinatorEmail ? result.coordinatorEmail : '');
      });
  }

  //Update Last Used Date
  updateLastUsedDate() {
    if (this.contractor.contractorId) {
      this.restService.updateLastUsedDate(
        this.contractor.contractorId,
        JSON.stringify(
          this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()!
        )
      );
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  uploadDocument(){
    this.documentUploadDialogRef = this.dialog.open(
      DocumentDetailsComponent,
      {
        disableClose: true,
        //closeOnNavigation: false,
        data: {
          contractor: this.contractor,
          userName: this.data.userName,
          userEmail: this.data.userEmail
        },
      }
    )
    this.documentUploadDialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = true;
      }
        // if(this.tabGroup.selectedIndex==7)
        // this.getDocumentDetailByContractorId();
      // else
      //   this.tabGroup.selectedIndex = 7;
      });
  }

  public toFilesBase64(
    files: File[],
    currentFile: SelectedFiles[]
  ): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          currentFile = currentFile?.filter(
            (f) => f?.name != files[i]?.name
          );
          currentFile.push({
            name: files[i]?.name,
            file: files[i],
            base64: reader?.result as string,
          });
          result.next(currentFile);
          if (files?.length === i + 1) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }


  onFileSelected(event: any) {
    if (event.target && event.target.files.length > 0 && FileTypes.includes(event.target.files[0].name.split('.').pop().toLowerCase())) {
      //remove any previous files from the array
      this.currentFile=[];
      this.pdfSrc='';
      this.isNewAttachment = true;
      this.isPdf = event.target.files[0].name.split('.').pop().toLowerCase() == "pdf"
      this.currentFile.splice(0, this.currentFile.length);

      this.toFilesBase64(
        Array.from(event.target.files),
        this.currentFile
      ).subscribe((res: SelectedFiles[]) => {
        this.currentFile = res;
        this.pdfSrc = this.currentFile[0].base64!;
      });

      this.openDialog();
    } else if (event.target && event.target.files.length > 0) {
      this.pdfSrc = '';
      //this.form.controls.documentFile.setValue(null);
      Swal.fire(
        'Only PDF, PNG, JPG, JPEG, or GIF files please!',
        'Sorry, you can only upload pdf, png, gif, jpeg, or jpg document here. Please convert your document to a pdf, png, gif, jpeg, or jpg and upload. <br/><br/>Nice try though... :)',
        'error'
      );
    }
    else {
      this.pdfSrc = '';
    }
    this.isChanged = true;
  }

  onEditScopeWorks(done:boolean){
    this.form.patchValue({ description: done? this.form.controls.scopeWorksContent.value : '' });
    if (this.dialogScopeWorksRef) {
      this.dialogScopeWorksRef.close();
    }
    this.cdr.detectChanges();
  }

  clearValidationMsg(){
    this.attachmentExceed20MB ='';
    this.attachSameFileName='';
  }

  onAddFile(){
    this.attachmentExceed20MB ='';
    this.attachSameFileName='';
    if (this.currentFile && this.currentFile.length > 0){
      if (this.selectedFiles.filter(x=>x.name === this.currentFile[0].name).length > 0){
        this.attachSameFileName = 'A file with the same name has already attached. You can either delete the attached file or rename the new file and attach again.';
      }
      else {
        if (this.currentFile[0].file.size > 20971520){
          this.attachmentExceed20MB = 'Attachment cannot exceed 20MB!. Please resize the file to try again.';   
        }
        else{
          const totalAttachmentsSize=this.selectedFiles
            .map((item) => Number.parseInt(item.file.size))
            .reduce((acc, curr) => acc + curr, 0);
          if (totalAttachmentsSize + this.currentFile[0].file.size > 20971520){
            this.attachmentExceed20MB = 'Attachment cannot exceed 20MB!. Please resize the file to try again.';   
          }
          else {
            this.selectedFiles.push(this.currentFile[0]);
            if (this.dialogAttachmentRef) {
              this.dialogAttachmentRef.close();
            }
            this.cdr.detectChanges();
          }
        }
      }
    }
    else {    
      if (this.dialogAttachmentRef) {
        this.dialogAttachmentRef.close();
      }
    }
  }

  openDocument(name : string){
    this.currentFile=[];
    this.currentFile.push(this.selectedFiles.filter(file => file.name === name)[0]);
    this.isPdf =  (this.currentFile[0].name.split('.').pop() || '').toLowerCase() === "pdf";
    this.isNewAttachment = false;
    if (name && this.currentFile){
      this.dialogAttachmentRef = this.dialogAttachment.open(this.attachmentDialog, {
        data: { currentFile: this.currentFile }
      });

    }
  }

  openDialog(): void {
    this.dialogAttachmentRef = this.dialogAttachment.open(this.attachmentDialog, {
      data: { currentFile: this.currentFile }
    });

    this.dialogAttachmentRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.cdr.detectChanges();
    });
  }

  removeAttachment(name:string){
    if (this.selectedFiles.filter(file => file.name === name).length>0){
      this.selectedFiles = this.selectedFiles.filter(file => file.name !== name);
    }
    if (this.dialogAttachmentRef) {
      this.dialogAttachmentRef.close();
    }
    this.cdr.detectChanges();
  }

  getAdditionalTradesEmailList() {
    let additionalTradesEmails = '';
    this.additionalInternalTradesArray.forEach(
      (internalTrade: InternalTrade) => {
        additionalTradesEmails += internalTrade.mail + ';';
      }
    );
    return additionalTradesEmails;
  }
}