import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { MsalGuard } from '@azure/msal-angular';
import { AlertService } from './_services';
import { ActivatedRoute, Router } from '@angular/router';
import { groups } from './auth-config';
import { AppDataSharingService } from './_services/app-data-sharing-service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  year: number = new Date().getFullYear();
  title = 'NIB/ Mk3 Contractors';
  isIframe = false;
  loginDisplay = false;
  activeUser = '';
  userType: number = 0;
  userGroupsArray: string[] = [];

  // 1 = groupIT:         "097cb0f8-ce3a-46cf-904c-7603266054ce",
  // 2 = groupManagement: "c47886cc-8994-4460-98ef-af73a225fc39",
  // 3 = groupAdmin:      "6b86718d-92ea-46a5-956d-73e14005366c",//objectID for Contractor_RW-Full group from Azure Portal
  // 4 = groupPrivileged: "05864855-23ea-4c45-8759-5254a67acd65",//objectID for Contractor_RW-Partial group from Azure Portal
  // 5 = groupMember:     "22527e48-0994-4484-86df-3680f1736d14"//objectID for Contractor_RO group from Azure Portal
  // 6 = User with Invoice Approval Permissions

  private readonly _destroying$ = new Subject<void>();
  public readonly _activatedRoute: ActivatedRoute;
  public readonly _router: Router;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    public msalGuard: MsalGuard,
    _activatedRoute: ActivatedRoute,
    _router: Router,
    public alertService: AlertService,
    private appDataSharingService: AppDataSharingService
  ) {
    this._activatedRoute = _activatedRoute;
    this._router = _router;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // Do user account/UI functions here
        this.setLoginDisplay();
      });

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        //console.log(result)
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;

    if (!this.loginDisplay) {
      this.checkAndSetActiveAccount();
      this.login();
    } else {
      this.getAccount();
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    if (activeAccount) {
      this.activeUser = activeAccount.name!;
    }
  }

  getAccount() {
    const currentAccounts = this.msalService.instance.getAllAccounts();

    if (currentAccounts === null) {
      return;
    } else if (currentAccounts.length > 1) {
      console.warn('Multiple accounts detected.');
      //this.setUserType((currentAccounts[0].idTokenClaims as any).groups);
    } else if (currentAccounts.length === 1) {
      //this.setUserType((currentAccounts[0].idTokenClaims as any).groups);
      this.activeUser = currentAccounts[0].name!;
    }

    this.userGroupsArray = [];
    (currentAccounts[0].idTokenClaims as any).groups.forEach((group: any) => {
      this.userGroupsArray.push(group);
    });
    this.appDataSharingService.userGroupsArray = this.userGroupsArray;
  }


  /*async getUserGroups(): Promise<MicrosoftGraph.Group[]> {
    const graphClient = this.getGraphClient();

    // Use the Graph API to get the user's group memberships
    const groups = await graphClient.api('/me/memberOf').version('beta').get();

    // Return the list of groups
    return groups.value;
  }

  private getGraphClient(): MicrosoftGraph.Client {
    const graphClient = MicrosoftGraph.Client.init({
      authProvider: async (done) => {
        const token = await this.msalService.acquireTokenSilent({
          scopes: ['openid', 'profile', 'email', 'https://graph.microsoft.com/Group.Read.All']
        });

        if (token) {
          done(null, token.accessToken);
        } else {
          done('Could not get access token', null);
        }
      }
    });*/

  /*setUserType(groupIDs: string[]) {
    try {
      if (groupIDs.includes(groups.groupIT)) {
        this.userType = 1; //IT
        return;
      } else if (groupIDs.includes(groups.groupFinance)) {
        this.userType = 9; //Finance
        return;
      } else if (
        groupIDs.includes(
          groups.groupManagement ||
            groups.groupAssessors ||
            groups.groupTeamLeaders
        )
      ) {
        this.userType = 6; //User with Invoice Approval Permissions
        return;
      } else if (groupIDs.includes(groups.groupCoordinators)) {
        this.userType = 10; //Claim Coordinators
        return;
      } else if (groupIDs.includes(groups.groupAssessors)) {
        this.userType = 11; //Assessors
        return;
      } else if (groupIDs.includes(groups.groupContractorRWPartial)) {
        this.userType = 4; //Privileged
        return;
      } else if (groupIDs.includes(groups.groupContractorRO)) {
        this.userType = 5; //Member
        return;
      }
    } catch (error: any) {
      console.log(error);
    }
  }*/

  /*getUserType() {
    return this.userType;
  }*/

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe(() => this.setLoginDisplay());
      } else {
        this.msalService.loginPopup().subscribe(() => this.setLoginDisplay());
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  logout() {
    this.msalService.logout();
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  public getUserName(): string {
    return this.msalService.instance.getActiveAccount()!.name!;
  }

  public getUserEmail(): string {
    return this.msalService.instance.getActiveAccount()!.username!;
  }
}
