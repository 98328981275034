import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { InputRefNoDialog } from '@app/home';
import { InternalTrade } from '@app/_models/internal-trade';
import { AlertService } from '@app/_services';
import { RestService } from '@app/_services/rest.service';
import { MatSort, MatSortable } from '@angular/material/sort';

@Component({
  selector: 'app-internal-trades',
  templateUrl: './internal-trades.component.html',
  styleUrls: ['./internal-trades.component.scss']
})
export class InternalTradesComponent implements OnInit {
  displayedColumns: string[] = ['employeeId', 'displayName', 'title', 'mobile', 'mail', 'state', 'details', 'send'];
  dataSource!: MatTableDataSource<InternalTrade>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  isLoading = true;
  inputDialogRef: any;
  internalTrade!: InternalTrade;

  constructor(public restService: RestService, private alertService: AlertService, public dialog: MatDialog,
    public appComponent: AppComponent, private router: Router) { }

  ngOnInit(): void {
    this.getRemoteData();
  }

  getRemoteData() {
    try {
      this.isLoading = true;
      this.restService.getInternalTrades().subscribe(
        internalTrades => {
          this.dataSource = new MatTableDataSource(internalTrades);
          this.matSort.sort(({ disableClear: true}) as MatSortable);
          this.dataSource.sort = this.matSort;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          return false;
        }
      );
      return true;
    }
    catch (error: any) {
      this.alertService.error(error);
      return false;
    }
  }

  internalTradeDetails(employeeId: number) {
    this.router.navigate(['../internal-trade-details/' + employeeId]);
  }

  newWorkInstructions(employeeId: number) {
    this.restService.getInternalTrade(employeeId).subscribe(
      (data: any) => {
        this.internalTrade = { ...data[0] };

        this.inputDialogRef = this.dialog.open(InputRefNoDialog, {
          minWidth: '400px',
          maxWidth: '1000px',
          data: { internalTrade: this.internalTrade, userName: this.appComponent.getUserName(), userEmail: this.appComponent.getUserEmail() }
        });
      });
  }
}
